import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import SubscribeConfirm from "../components/NewsletterSections/SubscribeConfirm";

class SubscribeConfirmPage extends Component {
  render() {
    return (
      <Layout>
        <div className="subscribeconfirm-container">
          <Helmet title={`Confirm your subscription | ${config.siteTitle}`} />
          <SubscribeConfirm />
        </div>
      </Layout>
    );
  }
}

export default SubscribeConfirmPage;
